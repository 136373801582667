$cdnURL: 'https://negosyo.test.shopee.ph/publish_static/c3ec22f6-90d5-4979-829d-13ac33c86f98/6791';
// @import '/styles/vars.scss';
@import '../../styles/vars.scss';
@import '../../styles/mixins/index.scss';
.benefits {
  .carouselWrap {
    position: relative;
    // padding-bottom: 28.57%;
    padding-bottom: 480px;
    width: 100%;
    background: $primary;
    overflow-y: hidden;
    .carousel {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .overLap {
        width: 988px;
        margin: 125px auto 0;
        transform: translateX(-28px);
        @media only screen and (max-width: 1420px) {
          width: 956px;
          transform: translateX(-12px);
        }
        display: flex;
        justify-content: space-between;
        &Title {
          font-size: 36px;
          line-height: 48px;
          @include shopee-2021-bold();
          color: $white;
        }
        &Desc {
          margin-top: 24px;
          font-size: 16px;
          line-height: 28px;
          color: rgba(249, 249, 249, 0.9);
          @include open-sans();
        }
        .IconGroup {
          margin-top: 38px;
          display: flex;

          &Left {
            img {
              display: block;
              width: 118px;
              height: 36px;
            }
            img:first-child {
              margin-bottom: 20px;
            }
          }
          &Right {
            margin-left: 24px;
            width: 76px;
            height: 96px;
            border-radius: 8px;
            background-color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              margin-top: 5px;
              width: 66px;
              height: 66px;
            }
            p {
              text-align: center;
              @include open-sans-semibold();
              width: 90px;
              height: 30px;
              font-size: 12px;
              line-height: 15px;
              transform: scale(0.666);
              margin-top: -4px;
              letter-spacing: -0.02em;
            }
          }
        }
        &Right {
          margin-top: -6px;
          width: 284px;
          height: 545px;
          border-radius: 24px;
          box-shadow: 0px 24px 68px rgba(0, 0, 0, 0.16);
          background: #ffffff;
          border: 1px solid #c4c4c4;
          padding: 41px 12px 44px 12px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  &Lace {
    height: 62px;
    width: 100%;
    position: absolute;
  }
  .Main {
    // width: 840px;
    // margin: 120px auto 200px;
    width: 1260px;
    margin: 120px auto 200px;
    h1 {
      padding: 0px;
      text-align: center;
      @include open-sans-semibold();
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 48px;
    }
    &Benefits,
    &Services {
      margin-bottom: 80px;
      .IconBoxLine {
        display: flex;
        justify-content: space-between;
        .IconBox {
          width: 288px;
          min-height: 262px;
          background: #fafafa;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 24px;
          padding-bottom: 24px;
          img {
            width: 110px;
            height: 110px;
          }
          p:nth-child(2) {
            margin-top: 24px;
            @include open-sans-semibold();
            text-align: center;
            font-size: 16px;
            line-height: 28px;
            color: $textBlack;
          }
          p:nth-child(3) {
            margin-top: 4px;
            padding: 0 22px;
            // width: 244px;
            text-align: center;
            @include open-sans();
            color: rgba(32, 35, 37, 0.6);
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    &Services {
      .IconBox2 {
        width: 288px;
        height: 262px;
        background: #fafafa;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 56px;
        img {
          width: 80px;
          height: 80px;
        }
        p:nth-child(2) {
          margin-top: 36px;
          @include open-sans();
          text-align: center;
          font-size: 16px;
          line-height: 28px;
          color: $textBlack;
        }
      }
    }
    &RegisterStep,
    &FAQs {
      h1 {
        margin-bottom: 80px;
      }
      .PartnerButton {
        margin: 0 auto;
        display: block;
        // width: 224px !important;
        height: 48px !important;
        border-radius: 24px !important;
        @include open-sans-semibold();
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 120px;
      }
      :global(.sg-carousel-carouselInner) {
        div {
          background-color: $white !important;
        }
      }
      .SwapBox {
        display: flex;
        &Left {
          margin-left: 176px;
          width: 284px;
          height: 545px;
          border: 1px solid #c4c4c4;
          box-sizing: border-box;
          box-shadow: 0px 24px 68px rgba(0, 0, 0, 0.16);
          border-radius: 24px;
          overflow: hidden;
          position: relative;
          &Inner {
            border-radius: 3px;
            border: 0.5px solid #c4c4c4;
            width: 260px;
            height: 460px;
            position: absolute;
            top: 36px;
            left: 12px;
          }
          &Carousel {
            :global(.sg-carousel-carouselPager) {
              z-index: -100;
            }
          }
          :global(.sg-carousel-carouselInner) {
            transition: all 0.3s ease 0s !important;
          }
        }
        &Right {
          margin-left: 152px;
          display: flex;
          flex-direction: column;
          width: 540px;
          > div {
            margin-bottom: 12px;
            padding-left: 24px;
            height: 104px;
            box-sizing: border-box;
            border-radius: 8px;
            display: flex;
            align-items: center;
            @include open-sans-semibold();
            cursor: pointer;
            .textLabel {
              font-size: 48px;
              color: $textGray;
            }
            .textGroup {
              margin-left: 24px;
              font-size: 16px;
              line-height: 28px;
              .textTitle {
                color: $textBlack;
              }
              .textDesc {
                @include open-sans();
                color: $textGray;
              }
            }
          }
          .SwapActive {
            border: 1px solid #e5e5e5;
            .textLabel {
              color: $primary;
            }
          }
        }
      }
    }
    &FAQs {
      margin-top: 80px;
      .PartnerButton {
        width: 174px !important;
        height: 48px !important;
      }
    }
  }
  @media only screen and (max-width: 1420px) {
    .Main {
      width: 980px;
      // margin: 120px auto 200px;
      &Benefits,
      &Services {
        .IconBoxLine {
          .IconBox {
            width: 224px;
            p:nth-child(3) {
              padding: 0 10px;
            }
            img {
              width: 88px;
              height: 88px;
            }
          }
        }
      }
      &Services {
        .IconBox2 {
          width: 224px;
        }
      }
      &RegisterStep,
      &FAQs {
        .SwapBox {
          &Left {
            margin-left: 94px;
          }
          &Right {
            margin-left: 126px;
            width: 476px;
          }
        }
      }
    }
  }
}
