// neutral colors
$title: #000;
$textBlack: #202325;
$textGray: rgba(32, 35, 37, 0.6);
$subtitle: rgba(0, 0, 0, 0.54);
$border: #eeeeee;
$borderLight: rgba(0, 0, 0, 0.09);
$background: rgba(0, 0, 0, 0.05);
$shadow: rgba(0, 0, 0, 0.1);
$bgGrey: #f7f8fc;
$lightOrange: #f6e6d9;
$white: white;
$black: #000;
// primary colors
$primary: #ee4d2d;
$mainColor: #ef4e2c;
$primaryHover: #fff4f4;
$primaryLight: #fff4f4;
// functional colors
// base size
$baseFont: 16;
$minWidth: 1280;
