@import '../vars';
@mixin media-breakpoint-md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lineClamp($lines: 2, $overflow: hidden) {
  display: -webkit-box;
  overflow: $overflow;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin border($direction: top, $color: $borderLight) {
  position: relative;
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    @if $direction==top {
      top: 0;
      bottom: auto;
      height: 1px;
      transform: scaleY(0.5);
    } @else if $direction==bottom {
      top: auto;
      height: 1px;
      transform: scaleY(0.5);
    } @else if $direction==left {
      right: auto;
      width: 1px;
      transform: scaleX(0.5);
    } @else {
      left: auto;
      width: 1px;
      transform: scaleX(0.5);
    }
    background: $borderLight;
  }
}

@mixin open-sans {
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
}

@mixin open-sans-semibold {
  font-family: 'Open Sans SemiBold' !important;
  font-weight: 600 !important;
}

@mixin open-sans-bold {
  font-family: 'Open Sans SemiBold' !important;
  font-weight: 700 !important;
}

@mixin shopee-2021-bold {
  font-family: 'Shopee 2021 Bold' !important;
  font-weight: 600 !important;
}

@mixin shopee-2021 {
  font-family: 'Shopee 2021' !important;
  font-weight: 400 !important;
}
